import { AppState } from './data'

export enum ActionType {
  ADD_LOADED_IMAGE = 'ADD_LOADED_IMAGE',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  CHANGE_ACCORDION = 'CHANGE_ACCORDION',
  CHANGE_CALENDAR_DATE = 'CHANGE_CALENDAR_DATE',
  CHANGE_GUEST = 'CHANGE_GUEST',
  CHANGE_HOTEL = 'CHANGE_HOTEL',
  CHANGE_ROOM_TYPE = 'CHANGE_ROOM_TYPE',
  CHANGE_VALIDATION_STATE = 'CHANGE_VALIDATION_STATE',
  CHANGE_VISIT_TYPE = 'CHANGE_VISIT_TYPE',
  CHANGE_VISIT_TYPE_CATEGORY = 'CHANGE_VISIT_TYPE_CATEGORY',
  LOAD_CALENDAR_PRICE_FAILURE = 'LOAD_CALENDAR_PRICE_FAILURE',
  LOAD_CALENDAR_PRICE_REQUEST = 'LOAD_CALENDAR_PRICE_REQUEST',
  LOAD_CALENDAR_PRICE_SUCCESS = 'LOAD_CALENDAR_PRICE_SUCCESS',
  LOAD_DATES_CHECKOUT_FAILURE = 'LOAD_DATES_CHECKOUT_FAILURE',
  LOAD_DATES_CHECKOUT_REQUEST = 'LOAD_DATES_CHECKOUT_REQUEST',
  LOAD_DATES_CHECKOUT_SUCCESS = 'LOAD_DATES_CHECKOUT_SUCCESS',
  LOAD_DATES_FAILURE = 'LOAD_DATES_FAILURE',
  LOAD_DATES_REQUEST = 'LOAD_DATES_REQUEST',
  LOAD_DATES_SUCCESS = 'LOAD_DATES_SUCCESS',
  LOAD_HOTELS_FAILURE = 'LOAD_HOTELS_FAILURE',
  LOAD_HOTELS_REQUEST = 'LOAD_HOTELS_REQUEST',
  LOAD_HOTELS_SUCCESS = 'LOAD_HOTELS_SUCCESS',
  LOAD_HOTEL_FAILURE = 'LOAD_HOTEL_FAILURE',
  LOAD_HOTEL_REQUEST = 'LOAD_HOTEL_REQUEST',
  LOAD_HOTEL_SUCCESS = 'LOAD_HOTEL_SUCCESS',
  LOAD_INIT_DATA_FAILURE = 'LOAD_INIT_DATA_FAILURE',
  LOAD_INIT_DATA_REQUEST = 'LOAD_INIT_DATA_REQUEST',
  LOAD_INIT_DATA_SUCCESS = 'LOAD_INIT_DATA_SUCCESS',
  LOAD_LOWEST_PRICE_FAILURE = 'LOAD_LOWEST_PRICE_FAILURE',
  LOAD_LOWEST_PRICE_REQUEST = 'LOAD_LOWEST_PRICE_REQUEST',
  LOAD_LOWEST_PRICE_SUCCESS = 'LOAD_LOWEST_PRICE_SUCCESS',
  LOAD_LOYALTY_PROGRAM_FAILURE = 'LOAD_LOYALTY_PROGRAM_FAILURE',
  LOAD_LOYALTY_PROGRAM_REQUEST = 'LOAD_LOYALTY_PROGRAM_REQUEST',
  LOAD_LOYALTY_PROGRAM_SUCCESS = 'LOAD_LOYALTY_PROGRAM_SUCCESS',
  LOAD_PRICE_FAILURE = 'LOAD_PRICE_FAILURE',
  LOAD_PRICE_REQUEST = 'LOAD_PRICE_REQUEST',
  LOAD_PRICE_SUCCESS = 'LOAD_PRICE_SUCCESS',
  LOAD_VISIT_CATEGORIES_FAILURE = 'LOAD_VISIT_CATEGORIES_FAILURE',
  LOAD_VISIT_CATEGORIES_REQUEST = 'LOAD_VISIT_CATEGORIES_REQUEST',
  LOAD_VISIT_CATEGORIES_SUCCESS = 'LOAD_VISIT_CATEGORIES_SUCCESS',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  RESET_APP = 'RESET_APP',
  RESET_CALENDAR = 'RESET_CALENDAR',
  SAVE_GUESTS_DATA = 'SAVE_GUESTS_DATA',
  SAVE_PERSONAL_DATA = 'SAVE_PERSONAL_DATA',
  SAVE_SERVICES_DATA = 'SAVE_SERVICES_DATA',
  SAVE_SUMMARY_DATA = 'SAVE_SUMMARY_DATA',
  SEND_BOOKING_FAILURE = 'SEND_BOOKING_FAILURE',
  SEND_BOOKING_REQUEST = 'SEND_BOOKING_REQUEST',
  SEND_BOOKING_SUCCESS = 'SEND_BOOKING_SUCCESS',
  SET_AFFILIATE_USERNAME = 'SET_AFFILIATE_USERNAME',
  SET_APP_INITIALIZED = 'SET_APP_INITIALIZED',
  SET_APP_INITIALIZED_AND_VALIDATED = 'SET_APP_INITIALIZED_AND_VALIDATED',
  SET_AVAILABLE_DEMAND = 'SET_AVAILABLE_DEMAND',
  SET_CALENDAR_MODE = 'SET_CALENDAR_MODE',
  SET_CALENDAR_TRAVEL_DATE = 'SET_CALENDAR_TRAVEL_DATE',
  SET_CURRENCY = 'SET_CURRENCY',
  SET_DEMAND = 'SET_DEMAND',
  SET_HOTEL_DIALOG_DATA = 'SET_HOTEL_DIALOG_DATA',
  SET_IS_FORM_FILLED = 'SET_IS_FORM_FILLED',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_LANGUAGE_INITIALIZED = 'SET_LANGUAGE_INITIALIZED',
  SET_LOADER = 'SET_LOADER',
  SET_LOADING_IMAGES = 'SET_LOADING_IMAGES_COUNT',
  SET_ROOM_TYPE_DIALOG_DATA = 'SET_ROOM_TYPE_DIALOG_DATA',
  SET_ROUTER_INITIALIZED = 'SET_ROUTER_INITIALIZED',
  SET_ROUTE_QUERY_PARAMS = 'SET_ROUTE_QUERY_PARAMS',
  SET_STEP = 'SET_STEP',
  SET_TRAVEL_DATE = 'SET_TRAVEL_DATE',
  SET_VISIT_TYPE_DIALOG_DATA = 'SET_VISIT_TYPE_DIALOG_DATA',
  VALIDATE_CALENDAR = 'VALIDATE_CALENDAR',
}

export interface IAction<T = AppState | unknown> {
  error?: Error
  payload?: T
  type: ActionType
}
