import { BasicContactFormValues } from '../forms/guests/useBasicContactForm'
import { GuestFormValues } from '../forms/guests/useGuestsForm'
import { PersonalDataFormValues } from '../forms/guests/usePersonalDataForm'
import { ServicesFormValues } from '../forms/services/useServicesForm'
import {
  AccordionEnum,
  ActionType,
  CalendarModeEnum,
  IAction,
  IBookingResponse,
  ICurrency,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  IDateWithCache,
  Identificator,
  IHotel,
  IHotelParamsQuery,
  IInitDataSuccessPayload,
  ILanguage,
  ILoaders,
  ILoyaltyProgram,
  INotification,
  IPrice,
  IRoomType,
  IRouteQueryParams,
  ITravelDate,
  IValidationState,
  IVisitCategory,
  IVisitType,
  SummaryFormData,
} from './interfaces'

export function savePersonalData(data: PersonalDataFormValues | BasicContactFormValues): IAction {
  return { payload: data, type: ActionType.SAVE_PERSONAL_DATA }
}

export function saveServices(data: ServicesFormValues): IAction {
  return { payload: data, type: ActionType.SAVE_SERVICES_DATA }
}

export function saveGuests(data: GuestFormValues): IAction {
  return { payload: data, type: ActionType.SAVE_GUESTS_DATA }
}

export function saveSummaryData(data: SummaryFormData): IAction {
  return { payload: data, type: ActionType.SAVE_SUMMARY_DATA }
}

export function loadDates(params: IDatesParamsQuery = {}): IAction {
  return { payload: params, type: ActionType.LOAD_DATES_REQUEST }
}

export function loadDatesSuccess(payload: IDateWithCache): IAction {
  return { payload, type: ActionType.LOAD_DATES_SUCCESS }
}

export function loadDatesFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_DATES_FAILURE,
  }
}

export function loadDatesCheckout(params: IDatesCheckoutParamsQuery): IAction {
  return { payload: params, type: ActionType.LOAD_DATES_CHECKOUT_REQUEST }
}

export function loadDatesCheckoutSuccess(payload: IDateCheckout[]): IAction {
  return { payload, type: ActionType.LOAD_DATES_CHECKOUT_SUCCESS }
}

export function loadDatesCheckoutFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_DATES_CHECKOUT_FAILURE,
  }
}

export function loadHotels(params: IHotelParamsQuery = {}): IAction {
  return { payload: params, type: ActionType.LOAD_HOTELS_REQUEST }
}

export function loadHotelsSuccess(payload: IHotel[]): IAction {
  return { payload, type: ActionType.LOAD_HOTELS_SUCCESS }
}

export function loadHotelsFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_HOTELS_FAILURE,
  }
}

export function loadInitData(payload: IRouteQueryParams): IAction {
  return {
    payload,
    type: ActionType.LOAD_INIT_DATA_REQUEST,
  }
}

export function loadInitDataSuccess(payload: IInitDataSuccessPayload): IAction {
  return { payload, type: ActionType.LOAD_INIT_DATA_SUCCESS }
}

export function loadInitDataFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_INIT_DATA_FAILURE,
  }
}

export function changeGuest(value: number | null): IAction {
  return { payload: value, type: ActionType.CHANGE_GUEST }
}

export function changeHotel(value: IHotel | null): IAction {
  return { payload: value, type: ActionType.CHANGE_HOTEL }
}

export function changeAccordion(value: AccordionEnum | null): IAction {
  return { payload: value, type: ActionType.CHANGE_ACCORDION }
}

export function changeVisitType(value: IVisitType | null): IAction {
  return { payload: value, type: ActionType.CHANGE_VISIT_TYPE }
}

export function changeVisitTypeCategory(value: IVisitCategory | null): IAction {
  return { payload: value, type: ActionType.CHANGE_VISIT_TYPE_CATEGORY }
}

export function changeRoomType(value: IRoomType | null): IAction {
  return { payload: value, type: ActionType.CHANGE_ROOM_TYPE }
}

export function changeValidationState(value: IValidationState): IAction {
  return { payload: value, type: ActionType.CHANGE_VALIDATION_STATE }
}

export function changeCalendarDate(value: Date): IAction {
  return { payload: value, type: ActionType.CHANGE_CALENDAR_DATE }
}

export function setHotelDialogData(data: IHotel | null): IAction {
  return { payload: data, type: ActionType.SET_HOTEL_DIALOG_DATA }
}

export function setVisitTypeDialogData(data: IVisitType | null): IAction {
  return { payload: data, type: ActionType.SET_VISIT_TYPE_DIALOG_DATA }
}

export function setRoomTypeDialogData(data: IRoomType | null): IAction {
  return { payload: data, type: ActionType.SET_ROOM_TYPE_DIALOG_DATA }
}

export function loadHotel(hotelId: Identificator): IAction {
  return { payload: hotelId, type: ActionType.LOAD_HOTEL_REQUEST }
}

export function loadHotelSuccess(payload: IHotel): IAction {
  return { payload, type: ActionType.LOAD_HOTEL_SUCCESS }
}

export function loadHotelFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_HOTEL_FAILURE,
  }
}

export function loadVisitCategories(): IAction {
  return { type: ActionType.LOAD_VISIT_CATEGORIES_REQUEST }
}

export function loadVisitCategoriesSuccess(payload: IVisitCategory[]): IAction {
  return { payload, type: ActionType.LOAD_VISIT_CATEGORIES_SUCCESS }
}

export function loadVisitCategoriesFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_VISIT_CATEGORIES_FAILURE,
  }
}

export function setDemand(value: boolean): IAction {
  return { payload: value, type: ActionType.SET_DEMAND }
}

export function setOnlyAvailableDemand(value: boolean): IAction {
  return { payload: value, type: ActionType.SET_AVAILABLE_DEMAND }
}

export function setCurrency(currency: ICurrency): IAction {
  return { payload: currency, type: ActionType.SET_CURRENCY }
}

export function setLanguage(lang: ILanguage): IAction {
  return { payload: lang, type: ActionType.SET_LANGUAGE }
}

export function resetApplication(): IAction {
  return { type: ActionType.RESET_APP }
}

export function resetCalendar(): IAction {
  return { type: ActionType.RESET_CALENDAR }
}

export function setTravelDate(data: ITravelDate): IAction {
  return { payload: data, type: ActionType.SET_TRAVEL_DATE }
}

export function loadLowestPrice(params: URLSearchParams): IAction {
  return { payload: params, type: ActionType.LOAD_LOWEST_PRICE_REQUEST }
}

export function loadLowestPriceSuccess(data: IPrice): IAction {
  return { payload: data, type: ActionType.LOAD_LOWEST_PRICE_SUCCESS }
}

export function loadLowestPriceFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_LOWEST_PRICE_FAILURE,
  }
}

export function loadPrice(params: URLSearchParams): IAction {
  return { payload: params, type: ActionType.LOAD_PRICE_REQUEST }
}

export function loadPriceSuccess(data: IPrice): IAction {
  return { payload: data, type: ActionType.LOAD_PRICE_SUCCESS }
}

export function loadPriceFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_PRICE_FAILURE,
  }
}

export function setCalendarMode(mode: CalendarModeEnum): IAction {
  return { payload: mode, type: ActionType.SET_CALENDAR_MODE }
}

export function setCalendarTravelDate(data: ITravelDate): IAction {
  return { payload: data, type: ActionType.SET_CALENDAR_TRAVEL_DATE }
}

export function setStep(value: number): IAction {
  return { payload: value, type: ActionType.SET_STEP }
}

export function loadCalendarPriceSuccess(data: IPrice): IAction {
  return { payload: data, type: ActionType.LOAD_CALENDAR_PRICE_SUCCESS }
}

export function loadCalendarPriceFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_CALENDAR_PRICE_FAILURE,
  }
}

export function loadLoyaltyProgram(): IAction {
  return { type: ActionType.LOAD_LOYALTY_PROGRAM_REQUEST }
}

export function loadLoyaltyProgramSuccess(data: ILoyaltyProgram[]): IAction {
  return { payload: data, type: ActionType.LOAD_LOYALTY_PROGRAM_SUCCESS }
}

export function loadLoyaltyProgramFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.LOAD_LOYALTY_PROGRAM_FAILURE,
  }
}

export function sendBookingRequest(): IAction {
  return {
    type: ActionType.SEND_BOOKING_REQUEST,
  }
}

export function sendBookingSuccess(data: IBookingResponse): IAction {
  return {
    payload: data,
    type: ActionType.SEND_BOOKING_SUCCESS,
  }
}

export function sendBookingFailure(error: Error): IAction {
  return {
    error,
    type: ActionType.SEND_BOOKING_FAILURE,
  }
}

export function addNotification(notification: INotification): IAction {
  return {
    payload: notification,
    type: ActionType.ADD_NOTIFICATION,
  }
}

export function removeNotification(notification: INotification): IAction {
  return {
    payload: notification,
    type: ActionType.REMOVE_NOTIFICATION,
  }
}

export function setIsFormFilled(isFormFilled: boolean): IAction {
  return {
    payload: isFormFilled,
    type: ActionType.SET_IS_FORM_FILLED,
  }
}

export function setLoader(loader: keyof ILoaders): IAction {
  return {
    payload: loader,
    type: ActionType.SET_LOADER,
  }
}

export function setLanguageInitialized(languageInitialized: boolean): IAction {
  return {
    payload: languageInitialized,
    type: ActionType.SET_LANGUAGE_INITIALIZED,
  }
}

export function setAppInitializedAndValidated(appInitializedAndValidated: boolean): IAction {
  return {
    payload: appInitializedAndValidated,
    type: ActionType.SET_APP_INITIALIZED_AND_VALIDATED,
  }
}

export function setAppInitialized(appInitialized: boolean): IAction {
  return {
    payload: appInitialized,
    type: ActionType.SET_APP_INITIALIZED,
  }
}

export function setAffiliateUsername(affilUserName: string): IAction {
  return {
    payload: affilUserName,
    type: ActionType.SET_AFFILIATE_USERNAME,
  }
}

export function setRouterInitialized(appInitialized: boolean): IAction {
  return {
    payload: appInitialized,
    type: ActionType.SET_ROUTER_INITIALIZED,
  }
}

export function setRouteQueryParams(routeQueryParams: IRouteQueryParams): IAction {
  return {
    payload: routeQueryParams,
    type: ActionType.SET_ROUTE_QUERY_PARAMS,
  }
}

export function setLoadingImages(loadingImagesCount: number): IAction {
  return {
    payload: loadingImagesCount,
    type: ActionType.SET_LOADING_IMAGES,
  }
}

export function addLoadedImage(): IAction {
  return {
    type: ActionType.ADD_LOADED_IMAGE,
  }
}
