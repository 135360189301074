import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  isDate,
  isValid,
  parse,
  startOfMonth,
  subMonths,
} from 'date-fns'
import Router from 'next/router'
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring'
import * as React from 'react'
import TagManager from 'react-gtm-module'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import removeAccents from 'remove-accents'
import { createSelector } from 'reselect'
import {
  AccordionEnum,
  CurrencyEnum,
  GuestFormData,
  ICurrency,
  IDate,
  IHotel,
  IInitDataParametersQuery,
  ILang,
  ILanguage,
  IPrice,
  IPriceBreakdownDiscount,
  IRoomType,
  IRouteQueryParams,
  IVisitType,
  LanguageEnum,
  PersonalFormData,
} from '@interfaces/data'
import * as selectors from '@redux/selectors'
import { store } from '@redux/store'
import { DATE_FORMAT_YYYY_MM_DD } from './constants'
import { SingleGuestFormValues } from './forms/guests/useGuestsForm'

export const getKeyLang = (language: ILanguage): keyof ILang => {
  if (language === 'cs') {
    return 'cs_CZ'
  }
  return language
}

export const getCurrencyFromLanguage = (language: ILanguage): ICurrency =>
  language === LanguageEnum.CS ? CurrencyEnum.CZK : CurrencyEnum.EUR

export const getLang = (data: any, language: ILanguage): string => {
  const getLangFallback = (obj: any): string => obj?.de || obj?.cs_CZ || obj?.en || obj?.ru || ''
  return data?.[getKeyLang(language)] || getLangFallback(data)
}

export const getValidGuestNumberOrNull = (guest: string | number | null | undefined) => {
  const guestNumber = Number(guest)

  // allowed guest count is 1 or 2
  return !isNaN(guestNumber) && guestNumber > 0 && guestNumber < 3 ? guestNumber : null
}

export const getLangIso = (iso: keyof ILang | 'cs'): ILanguage => {
  if (iso === 'cs_CZ') {
    return 'cs'
  }

  if (['en', 'de', 'cs', 'ru'].includes(iso)) {
    return iso as ILanguage
  }

  return 'de'
}

export const getRouteQueryParameters = (query: ParsedUrlQuery) => {
  const queryParams: IRouteQueryParams = {}

  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key]

      if (key === 'lang') {
        // Ensure that the value for lang is one of the keys of ILang or null
        queryParams.lang = typeof value === 'string' ? (value as keyof ILang) : null
      } else if (key !== 'lang') {
        // Handle other keys normally. Next.js sometimes returns query params as arrays, this takes the first value if that's the case
        queryParams[key as keyof Omit<IRouteQueryParams, 'lang'>] = Array.isArray(value)
          ? value[0]
          : value
      }
    }
  }

  return queryParams
}

const isPriceNumber = (price: number | string) => {
  const num = typeof price === 'string' ? parseFloat(price) : price
  return !isNaN(num) && isFinite(num)
}

export const getPriceOrNull = (data: any, field: string, language: ILanguage): number | null => {
  const price = data?.[getKeyLang(language)]?.[field]

  return isPriceNumber(price) ? price : null
}

export const getPrice = (data: any, field: string, language: ILanguage): number =>
  getPriceOrNull(data, field, language) || 0

export const getBasePrice = (data: any, language: ILanguage): number => {
  const basePrice = data?.[getKeyLang(language)]?.price_breakdown?.base_price

  return isPriceNumber(basePrice) ? basePrice : 0
}

export const getAllDiscounts = (data: any, language: ILanguage) => {
  return data?.[getKeyLang(language)]?.price_breakdown?.discounts ?? []
}

export const getTotalDiscountedPrice = (data: any, language: ILanguage): number => {
  const allDiscounts = getAllDiscounts(data, language)

  const totalDiscountedPrice = allDiscounts.reduce(
    (accumulator: number, discount: IPriceBreakdownDiscount) => {
      return accumulator - discount.price_discount
    },
    0
  )

  return totalDiscountedPrice
}

export enum PathEnum {
  STEP_1 = '/',
  STEP_2 = '/personal-data',
  STEP_3 = '/guests',
  STEP_4 = '/services',
  STEP_5 = '/summary',
  STEP_6 = '/finish',
}

export const redirectTo = async (
  path: PathEnum,
  query: IRouteQueryParams | undefined = store.getState().routeQueryParams,
  lang: ILanguage | undefined = store.getState().language
): Promise<void> => {
  const encodedQuery = encodeRouteQueryValues(query)
  const routeQuery = { ...encodedQuery, lang: getKeyLang(lang) }
  await Router.push({ pathname: path, query: routeQuery as ParsedUrlQueryInput })
}

export const encodeRouteQueryValues = (routeQuery: IRouteQueryParams) => {
  const encodedRouteQuery: Record<string, string> = {}

  for (const [key, value] of Object.entries(routeQuery)) {
    if (value) {
      encodedRouteQuery[key] = removeAccents(value.toString())
    }
  }

  return encodedRouteQuery
}

export const sanitizeBookingParams = (params: IInitDataParametersQuery) => {
  const { guest, hotel_name, room_type_category, visit_type_name } = params

  return {
    ...params,
    guest: getValidGuestNumberOrNull(guest),
    hotel_name: hotel_name ? removeAccents(hotel_name) : null,
    room_type_category: room_type_category ? removeAccents(room_type_category) : null,
    visit_type_name: visit_type_name ? removeAccents(visit_type_name) : null,
  }
}

const getUniqueId = (): string => {
  let uid = window.sessionStorage.getItem('uid_user')
  if (!uid) {
    uid = Math.random().toString(36).substr(2, 18)
    window.sessionStorage.setItem('uid_user', uid)
  }
  return uid
}

export const reportConversion = (
  event: 'beginCheckout' | 'conversionDemand' | 'conversionBooking',
  language: ILanguage,
  price: IPrice | null,
  currency: ICurrency,
  visitType: IVisitType | null,
  roomType: IRoomType | null,
  hotel: IHotel | null
): void => {
  if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
    const id = getUniqueId()
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: {
              id,
              revenue: getPrice(price, 'amount', language),
            },
            products: [
              {
                category: getLang(roomType?.category, language),
                id: visitType?.id || null,
                name: getLang(visitType?.name, language),
                price: getPrice(price, 'amount', language),
                quantity: 1,
              },
            ],
          },
        },
        event,
        hotel: hotel?.name,
        items: [
          {
            currency: currency,
            item_id: visitType?.id || null,
            item_name: getLang(visitType?.name, language),
            item_variant: getLang(roomType?.category, language),
            price: getPrice(price, 'amount', language),
            quantity: 1,
          },
        ],
        room: getLang(roomType?.category, language),
        shipping: 0,
        transaction_id: id,
        value: getPrice(price, 'amount', language),
        visit_type: getLang(visitType?.name, language),
      },
    })
  }
}

export const useGoogleTagManager = (): void => {
  const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || null
  React.useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [gtmId])
}

export const getTermsAndConditionsLink = (language: ILanguage): string => {
  switch (language) {
    case LanguageEnum.CS:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/cs/vseobecne-obchodni-podminky`
    case LanguageEnum.DE:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/de/allgemeinen-vertragsbedingungen`
    case LanguageEnum.RU:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/ru/obshhie-kommercheskie-uslovija`
    case LanguageEnum.EN:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/en/general-terms-and-conditions`
    default:
      return '#'
  }
}

export const getPersonalDataProcessingLink = (language: ILanguage): string => {
  switch (language) {
    case LanguageEnum.CS:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/cs/zpracovani-osobnich-udaju`
    case LanguageEnum.DE:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/de/verarbeitung-personenbezogener-daten`
    case LanguageEnum.RU:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/ru/obrabotka-personalnykh-dannykh`
    case LanguageEnum.EN:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/en/personal-data-processing`
    default:
      return '#'
  }
}

export const getContactLink = (language: ILanguage): string => {
  switch (language) {
    case LanguageEnum.CS:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/cs/kontakty`
    case LanguageEnum.DE:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/de/kontakt`
    case LanguageEnum.RU:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/ru/kontakty`
    case LanguageEnum.EN:
      return `${process.env.NEXT_PUBLIC_LANDING_PAGE}/en/contacts`
    default:
      return '#'
  }
}

export const useCloseWithEscKey = (handler: () => void): void => {
  React.useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handler()
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  })
}

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: () => void
): void {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current
      if (!el || el.contains(event.target as Node)) {
        return
      }
      handler()
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

// for Yup transform @internal
export const getDateOrNull = (_: any, originalValue: any) => {
  if (!originalValue) return null

  return isDate(originalValue) ? originalValue : new Date(originalValue)
}

export const parseDateString = (date: string) => parse(date, DATE_FORMAT_YYYY_MM_DD, new Date())

export const useBookingDetails = () => {
  const { guest, hotel, language, travelDate, visitType } = useSelector(basicSelector)

  const bookingDetails: React.ReactNode[] = React.useMemo(() => {
    const data = []
    if (visitType) {
      data.push(getLang(visitType?.name, language))
    }

    if (hotel && hotel?.name) {
      data.push(hotel.name)
    }

    if (guest) {
      data.push(
        <FormattedMessage
          defaultMessage="{count, plural, one {#{whiteSpace}Guest} other {#{whiteSpace}Guests}}"
          description="Count guests in Booking details"
          values={{ count: parseInt(String(guest || 0)), whiteSpace: '\u00A0' }}
        />
      )
    }

    if (travelDate.from && travelDate.to) {
      data.push(
        <FormattedMessage
          defaultMessage="{count, plural, one {#{whiteSpace}night} other {#{whiteSpace}nights}}"
          description="Count nights in Booking details"
          values={{ count: differenceInDays(travelDate.to, travelDate.from), whiteSpace: '\u00A0' }}
        />
      )
    }

    return data
  }, [guest, visitType, hotel, language, travelDate])

  return { data: bookingDetails }
}

export const getCachedMonth = (dateCacheKey: string) => {
  return createSelector([selectors.getDateRangesCache], (datesCache: Record<string, IDate[]>) => {
    if (datesCache.hasOwnProperty(dateCacheKey)) {
      return datesCache[dateCacheKey]
    }
    return null
  })
}

export const dateRangesCacheKeys = createSelector(
  [selectors.getDateRangesCache],
  (datesCache: Record<string, IDate[]>) => {
    return Object.keys(datesCache)
  }
)

export const basicSelector = createSelector(
  selectors.getVisitType,
  selectors.getVisitTypeCategory,
  selectors.getHotel,
  selectors.getRoomType,
  selectors.getTravelDate,
  selectors.getLowestPrice,
  selectors.getPrice,
  selectors.getStep,
  selectors.getGuest,
  selectors.isDemand,
  selectors.isOnlyAvailableDemand,
  selectors.getAccordion,
  selectors.getHotels,
  selectors.getPersonalData,
  selectors.getServicesData,
  selectors.getSummaryData,
  selectors.getDatesCheckout,
  selectors.getLoyaltyProgramData,
  selectors.getGuestsData,
  selectors.getVisitCategories,
  selectors.getDates,
  selectors.getDateRangesCache,
  selectors.isDatesFetching,
  selectors.getCalendarDate,
  selectors.getCalendarState,
  selectors.getCalendarPrice,
  selectors.getCalendarTravelDate,
  selectors.getCalendarMode,
  selectors.getCurrency,
  selectors.getLanguage,
  selectors.isFetching,
  selectors.isFormFilled,
  selectors.getLoaders,
  selectors.getLanguageInitialized,
  selectors.getAppInitialized,
  selectors.getRouteQueryParams,
  (
    visitType,
    visitTypeCategory,
    hotel,
    roomType,
    travelDate,
    lowestPrice,
    price,
    step,
    guest,
    demand,
    onlyAvailableDemand,
    accordion,
    hotels,
    personalData,
    serviceData,
    summaryData,
    datesCheckout,
    loyaltyProgramData,
    guestsData,
    visitCategories,
    dates,
    datesCache,
    isDatesFetching,
    calendarDate,
    calendarState,
    calendarPrice,
    calendarTravelDate,
    calendarMode,
    currency,
    language,
    isFetching,
    isFormFilled,
    loaders,
    languageInitialized,
    appInitialized,
    routeQueryParams
  ) => ({
    accordion,
    appInitialized,
    calendarDate,
    calendarMode,
    calendarPrice,
    calendarState,
    calendarTravelDate,
    currency,
    dates,
    datesCache,
    datesCheckout,
    demand,
    guest,
    guestsData,
    hotel,
    hotels,
    isAllBookingParams: Boolean(
      guest && visitType && hotel && roomType && travelDate.from && travelDate.to
    ),
    isDataFilled: Boolean(
      guest || visitType || hotel || roomType || travelDate.from || travelDate.to || isFormFilled
    ),
    isDatesFetching,
    isFetching,
    isFormFilled,
    language,
    languageInitialized,
    loaders,
    lowestPrice,
    loyaltyProgramData,
    onlyAvailableDemand,
    personalData,
    price,
    roomType,
    routeQueryParams,
    serviceData,
    step,
    summaryData,
    travelDate,
    visitCategories,
    visitType,
    visitTypeCategory,
  })
)

export const resolveNextAccordion = (currentAccordion: AccordionEnum) => {
  if (currentAccordion === AccordionEnum.HOTELS) return AccordionEnum.ROOMS

  const { guest, hotel, roomType, visitType } = store.getState()
  const accordionValues = [guest, visitType, hotel, roomType]
  const nextAccordion = currentAccordion + 1
  const accordionsAfter = accordionValues.slice(nextAccordion)
  const emptyAccordion = accordionsAfter.findIndex((accordion) => !accordion)

  return emptyAccordion === -1 ? AccordionEnum.CALENDAR : nextAccordion + emptyAccordion
}

export const getGuestFullName = (guest: GuestFormData) => {
  const { first_name, last_name } = guest

  const trimmedFirstName = first_name?.trim()
  const trimmedLastName = last_name?.trim()

  if (trimmedFirstName && trimmedLastName) {
    return `${trimmedFirstName} ${trimmedLastName}`
  } else if (trimmedFirstName) {
    return trimmedFirstName
  } else if (trimmedLastName) {
    return trimmedLastName
  }

  return null
}

export const isGuestDefined = (
  guestData: Omit<GuestFormData, 'isEditing'> | SingleGuestFormValues
) => {
  return !!(
    guestData?.first_name &&
    guestData?.last_name &&
    guestData?.gender &&
    guestData?.birth_date
  )
}

export const getDateForDatePickerComponent = (date: string | null) => {
  if (!date) return null

  return !isValid(new Date(date)) ? null : new Date(date)
}

export const getPersonalData = (personalData: PersonalFormData) => {
  return {
    ...personalData,
    birth_date: getDateForDatePickerComponent(personalData.birth_date),
  }
}

export const getDatesForCalendarDate = (calendarDate: string, dates: IDate[]) => {
  const input = new Date(calendarDate)
  const inputMonth = input.getMonth()
  const inputYear = input.getFullYear()

  return dates.filter(({ date: dateString }) => {
    const date = new Date(dateString)
    return date.getMonth() === inputMonth && date.getFullYear() === inputYear
  })
}

export const getDateKeysForCache = (
  dateFromCalendarDate: Date,
  dateMiddleCalendarDate: Date,
  dateToCalendarDate: Date
) => {
  const dateMiddleCalendarDateCacheKey = format(dateMiddleCalendarDate, DATE_FORMAT_YYYY_MM_DD)
  const dateToCalendarDateCacheKey = format(
    startOfMonth(dateToCalendarDate),
    DATE_FORMAT_YYYY_MM_DD
  )

  const dateFromCalendarDateRequest = format(dateFromCalendarDate, DATE_FORMAT_YYYY_MM_DD)
  // [SPA-144] added one day, due to backend bug with date_to parameter
  const dateToCalendarDateRequest = format(addDays(dateToCalendarDate, 1), DATE_FORMAT_YYYY_MM_DD)

  return {
    dateFromCalendarDateRequest,
    dateMiddleCalendarDateCacheKey,
    dateToCalendarDateCacheKey,
    dateToCalendarDateRequest,
  }
}

export const getRangeForCalendarDatesRequest = (
  calendarDate: Date,
  dateRangesCacheKeysSelector: string[],
  isCalendarInitial: boolean
) => {
  const start = startOfMonth(calendarDate)

  // if initial: load 1 month back, 1 month current and 1 next month
  // if not initial - we already have current and next month, so we will switch and load only 1 month in the future
  const dateFromCalendarDate = isCalendarInitial
    ? subMonths(start, 1)
    : startOfMonth(addMonths(calendarDate, 1))
  const dateMiddleCalendarDate = isCalendarInitial
    ? start
    : startOfMonth(addMonths(calendarDate, 1))
  const dateToCalendarDate = isCalendarInitial
    ? endOfMonth(addMonths(calendarDate, 1))
    : endOfMonth(addMonths(calendarDate, 1))

  const dateStartCacheKey = format(startOfMonth(dateToCalendarDate), DATE_FORMAT_YYYY_MM_DD)

  return {
    dateFromCalendarDate,
    dateMiddleCalendarDate,
    dateToCalendarDate,
    sendRequest: !dateRangesCacheKeysSelector.includes(dateStartCacheKey),
  }
}
