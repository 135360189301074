import { useQueryState } from 'next-usequerystate'
import { useCallback } from 'react'
import { ILang, ILanguage, IRouteQueryParams } from '@interfaces/data'

interface QueryParams {
  addQueryParameter: (param: keyof IRouteQueryParams, value: string | null) => void
  removeQueryParameter: (param: keyof IRouteQueryParams) => void
  removeQueryParametersArray: (params: Array<keyof IRouteQueryParams>) => void
  routerQueryParams: IRouteQueryParams
}

export const GUEST_PARAM = 'guest'
export const HOTEL_PARAM = 'hotel'
export const LANG_PARAM = 'lang'
export const ROOM_TYPE_PARAM = 'roomType'
export const VISIT_START_PARAM = 'visitStart'
export const VISIT_END_PARAM = 'visitEnd'
export const VISIT_TYPE_PARAM = 'visitType'
export const AFFIL_USER_NAME = 'affilUserName'

export const useQueryParams = (): QueryParams => {
  const [guest, setGuest] = useQueryState(GUEST_PARAM)
  const [hotel, setHotel] = useQueryState(HOTEL_PARAM)
  const [lang, setLang] = useQueryState(LANG_PARAM)
  const [roomType, setRoomType] = useQueryState(ROOM_TYPE_PARAM)
  const [visitStart, setVisitStart] = useQueryState(VISIT_START_PARAM)
  const [visitEnd, setVisitEnd] = useQueryState(VISIT_END_PARAM)
  const [visitType, setVisitType] = useQueryState(VISIT_TYPE_PARAM)
  const [affilUserName, setAffilUserName] = useQueryState(AFFIL_USER_NAME)

  const setQueryParameterValueByName = (param: keyof IRouteQueryParams, value: string | null) => {
    switch (param) {
      case GUEST_PARAM: {
        setGuest(value)
        break
      }
      case HOTEL_PARAM: {
        setHotel(value)
        break
      }
      case LANG_PARAM: {
        setLang(value)
        break
      }
      case ROOM_TYPE_PARAM: {
        setRoomType(value)
        break
      }
      case VISIT_START_PARAM: {
        setVisitStart(value)
        break
      }
      case VISIT_END_PARAM: {
        setVisitEnd(value)
        break
      }
      case VISIT_TYPE_PARAM: {
        setVisitType(value)
        break
      }
      case AFFIL_USER_NAME: {
        setAffilUserName(value)
        break
      }
      default:
    }
  }

  const addQueryParameter = useCallback((param: keyof IRouteQueryParams, value: string | null) => {
    setQueryParameterValueByName(param, value)
  }, [])

  const removeQueryParameter = useCallback((param: keyof IRouteQueryParams) => {
    setQueryParameterValueByName(param, null)
  }, [])

  const removeQueryParametersArray = useCallback((params: Array<keyof IRouteQueryParams>) => {
    params.forEach((param) => {
      removeQueryParameter(param)
    })
  }, [])

  const routerQueryParams = {
    affilUserName,
    guest,
    hotel,
    lang: lang as keyof ILang | ILanguage | null,
    roomType,
    visitEnd,
    visitStart,
    visitType,
  }

  return {
    addQueryParameter,
    removeQueryParameter,
    removeQueryParametersArray,
    routerQueryParams,
  }
}
