import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from '@components/AppContext'
import { setAffiliateUsername, setRouterInitialized } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { emailValidationSchema } from '../forms/guests/validation'
import { getRouteQueryParameters } from '../helpers'
import {
  AFFIL_USER_NAME,
  GUEST_PARAM,
  HOTEL_PARAM,
  LANG_PARAM,
  ROOM_TYPE_PARAM,
  useQueryParams,
  VISIT_END_PARAM,
  VISIT_START_PARAM,
  VISIT_TYPE_PARAM,
} from '../hooks/useQueryParams'

interface RouterReadyProps {
  children: ReactNode
}

// We need to wait for the router to be ready (see more: https://github.com/vercel/next.js/discussions/11484)
export const RouterReady = ({ children }: RouterReadyProps) => {
  const { language } = useAppContext()
  const router = useRouter()
  const dispatch = useDispatch()

  const { addQueryParameter, removeQueryParameter, routerQueryParams } = useQueryParams()
  const routerInitialized = useSelector(selectors.getRouterInitialized)
  const affiliateUsername = useSelector(selectors.getAffiliateUsername)

  useEffect(() => {
    if (router.isReady && !routerInitialized) {
      const params = getRouteQueryParameters(router.query)
      addQueryParameter(GUEST_PARAM, params?.guest ?? null)
      addQueryParameter(HOTEL_PARAM, params?.hotel ?? null)
      addQueryParameter(LANG_PARAM, params?.lang || language || null)
      addQueryParameter(ROOM_TYPE_PARAM, params?.roomType ?? null)
      addQueryParameter(VISIT_START_PARAM, params?.visitStart ?? null)
      addQueryParameter(VISIT_END_PARAM, params?.visitEnd ?? null)
      addQueryParameter(VISIT_TYPE_PARAM, params?.visitType ?? null)
      addQueryParameter(AFFIL_USER_NAME, params?.affilUserName ?? null)

      dispatch(setRouterInitialized(true))
    }
  }, [router.isReady, routerInitialized])

  useEffect(() => {
    if (
      router.isReady &&
      routerInitialized &&
      routerQueryParams?.affilUserName &&
      affiliateUsername === null
    ) {
      try {
        emailValidationSchema.validateSync(routerQueryParams.affilUserName)

        dispatch(setAffiliateUsername(routerQueryParams.affilUserName))
      } catch (error) {
        removeQueryParameter(AFFIL_USER_NAME)
      }
    }
  }, [routerQueryParams?.affilUserName, router.isReady, routerInitialized, affiliateUsername])

  if (!routerInitialized) {
    return null
  }

  return <>{children}</>
}
