const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: ['./src/pages/**/*.{js,ts,jsx,tsx}', './src/components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    colors: {
      accent: {
        from: '#B5841C',
        to: '#AE7100',
      },
      background: '#FDFDFD',
      black: '#1F2325',
      brown: '#8F4500',
      card: {
        background: '#F4F6F7',
        default: '#EBF0F2',
        hover: '#E5E7EB',
      },
      error: '#DD3044',
      gray: '#5E6E76',
      green: {
        highlight: '#BEFFD4',
        main: '#00743C',
      },
      orange: {
        highlight: '#FCF3C5',
        main: '#8F4500',
      },
      primary: {
        dark: '#A37719',
        light: '#CF9720',
        main: '#B37D11',
        transparent: '#B37D111A',
      },
      red: {
        highlight: '#FCE9EC',
        main: '#DF4052',
      },
      transparent: 'transparent',
      white: '#FFFFFF',
      yellow: {
        highlight: '#793A00',
        main: '#FFCA0F',
      },
    },
    extend: {
      minHeight: {
        card: '140px',
      },
    },
    fontFamily: {
      sans: ['Jost', ...defaultTheme.fontFamily.sans],
      serif: ['Prata', ...defaultTheme.fontFamily.serif],
    },
    fontSize: {
      body: ['16px', '20px'],
      'body-mobile': ['16px', '20px'],
      caption: ['10px', '16px'],
      category: ['24px', '48px'],
      'category-mobile': ['20px', '24px'],
      'extra-small': ['11px', '16px'],
      headline: ['56px', '68px'],
      'headline-mobile': ['32px', '44px'],
      small: ['14px', '20px'],
      subcategory: '20px',
      'subcategory-mobile': ['18px', '24px'],
    },
    screens: {
      '2xl': '1536px',
      lg: '1024px',
      md: '768px',
      sm: '640px',
      xl: '1280px',
      xs: '375px',
    },
  },
}
