import clsx from 'classnames'
import * as React from 'react'
import ReactLoading from 'react-loading'
import { theme } from '../../tailwind.config'
import styles from './ScreenLoader.module.scss'

interface ScreenLoaderProps {
  isLoading: boolean
}

const ScreenLoader: React.FC<React.PropsWithChildren<ScreenLoaderProps>> = ({
  children,
  isLoading = false,
}) => (
  <>
    {isLoading ? (
      <ReactLoading
        className={clsx(styles.root)}
        color={theme.colors.primary.main}
        height={'2%'}
        type="spin"
        width={'2%'}
      />
    ) : (
      children
    )}
  </>
)

export default ScreenLoader
