import {
  AccordionEnum,
  AppState,
  CalendarModeEnum,
  CalendarStateEnum,
  GuestsFormData,
  ICurrency,
  IDate,
  IDateCheckout,
  IHotel,
  ILanguage,
  ILoyaltyProgram,
  INotification,
  IPrice,
  IRoomType,
  ITravelDate,
  IValidationState,
  IVisitCategory,
  IVisitType,
  PersonalFormData,
  ServiceFormData,
  SummaryFormData,
} from './interfaces'

export const getAccordion = (state: AppState): AccordionEnum | null => state.accordion
export const getCalendarDate = (state: AppState): Date => state.calendar.date
export const getCalendarPrice = (state: AppState): IPrice | null => state.calendar.price
export const getCalendarState = (state: AppState): CalendarStateEnum => state.calendar.state
export const getCalendarTravelDate = (state: AppState): ITravelDate => state.calendar.travelDate
export const getCalendarMode = (state: AppState): CalendarModeEnum => state.calendar.mode
export const getCurrency = (state: AppState): ICurrency => state.currency
export const getDates = (state: AppState): IDate[] => state.calendar.data
export const getDateRangesCache = (state: AppState): Record<string, IDate[]> =>
  state.calendar.datesCache
export const getDatesCheckout = (state: AppState): IDateCheckout[] => state.calendar.checkout
export const getGuest = (state: AppState): number | null => state.guest
export const getGuestsData = (state: AppState): GuestsFormData => state.guestsData
export const getHotel = (state: AppState): IHotel | null => state.hotel
export const getHotelDialog = (state: AppState): IHotel | null => state.hotelDialog.data
export const getHotels = (state: AppState): IHotel[] => state.hotels
export const getLanguage = (state: AppState): ILanguage => state.language
export const getLowestPrice = (state: AppState): IPrice | null => state.lowestPrice
export const getLoyaltyProgramData = (state: AppState): ILoyaltyProgram[] => state.loyaltyProgram
export const getNotifications = (state: AppState): INotification[] => state.notifications
export const getPersonalData = (state: AppState): PersonalFormData => state.personalData
export const getPrice = (state: AppState): IPrice | null => state.price
export const getRoomType = (state: AppState): IRoomType | null => state.roomType
export const getRoomTypeDialog = (state: AppState): IRoomType | null => state.roomTypeDialog.data
export const getServicesData = (state: AppState): ServiceFormData => state.servicesData
export const getStep = (state: AppState): number => state.stepper.step
export const getSummaryData = (state: AppState): SummaryFormData => state.summaryData
export const getTravelDate = (state: AppState): ITravelDate => state.travelDate
export const getVisitCategories = (state: AppState): IVisitCategory[] => state.visitCategories
export const getValidationState = (state: AppState): IValidationState => state.validationState
export const getVisitType = (state: AppState): IVisitType | null => state.visitType
export const getVisitTypeCategory = (state: AppState): IVisitCategory | null =>
  state.visitTypeCategory
export const getVisitTypeDialog = (state: AppState): IVisitType | null => state.visitTypeDialog.data
export const isDatesFetching = (state: AppState): boolean => state.calendar.isFetching
export const isDemand = (state: AppState): boolean => state.demand
export const isFetching = (state: AppState): boolean => state.isFetching
export const isFormFilled = (state: AppState): boolean => state.isFormFilled
export const isOnlyAvailableDemand = (state: AppState): boolean => state.onlyDemand
export const getLoaders = (state: AppState) => state.loaders
export const getLanguageInitialized = (state: AppState) => state.languageInitialized
export const getAppInitializedAndValidated = (state: AppState) => state.appInitializedAndValidated
export const getRouteQueryParams = (state: AppState) => state.routeQueryParams
export const getAppInitialized = (state: AppState) => state.appInitialized
export const getAffiliateUsername = (state: AppState) => state?.affiliate?.affilUserName ?? null
export const getRouterInitialized = (state: AppState) => state.routerInitialized
export const getImages = (state: AppState) => state.images
