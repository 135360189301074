import { AxiosResponse } from 'axios'
import {
  IBookingRequest,
  IBookingResponse,
  IDate,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  Identificator,
  IHotel,
  IHotelParamsQuery,
  IInitDataParametersQuery,
  IInitDataResponse,
  ILanguage,
  ILoyaltyProgram,
  IPrice,
  IVisitCategory,
} from '@interfaces/data'
import { getKeyLang } from '../../helpers'
import axios from './axios'

const GET_DATES_DEBOUNCE_INTERVAL = 10

let abortController: AbortController | null = null
let debounceTimer: NodeJS.Timeout | null = null

export const getDates = (params: IDatesParamsQuery): Promise<AxiosResponse<IDate[]>> => {
  if (debounceTimer) {
    clearTimeout(debounceTimer)
  }

  if (abortController) {
    abortController.abort()
  }

  return new Promise((resolve, reject) => {
    debounceTimer = setTimeout(() => {
      abortController = new AbortController()

      axios
        .get<IDate[]>('/calendar/dates', {
          params,
          signal: abortController.signal,
        })
        .then((response) => {
          abortController = null
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }, GET_DATES_DEBOUNCE_INTERVAL)
  })
}

export const getDatesCheckout = (params: IDatesCheckoutParamsQuery): Promise<IDateCheckout[]> =>
  axios.get('/calendar/checkouts', { params })
export const getHotel = (hotelId: Identificator): Promise<IHotel> => axios.get(`/hotels/${hotelId}`)
export const getHotels = (params: IHotelParamsQuery): Promise<IHotel[]> =>
  axios.get('/hotels', { params })
export const getLowestPrice = (params: URLSearchParams): Promise<IPrice> =>
  axios.get('/lowest-price', { params })
export const getLoyaltyProgram = (): Promise<ILoyaltyProgram[]> => axios.get('/loyalty-programs')
export const getPrice = (params: URLSearchParams): Promise<IPrice> =>
  axios.get('/price', { params })
export const getVisitCategories = (): Promise<IVisitCategory[]> =>
  axios.get('/visit-type-categories', { params: { include_visit_types: true } })
export const sendBooking = (
  data: IBookingRequest,
  language: ILanguage
): Promise<IBookingResponse> =>
  axios.post('/booking', data, { headers: { 'Accept-Language': getKeyLang(language) } })
export const getBookingConfiguration = (
  params: IInitDataParametersQuery
): Promise<IInitDataResponse> => axios.get('/booking', { params })
